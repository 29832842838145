.adSection{
    display: block;
}

.gridStyle {
  width: '20%',
}

.main-description {
    white-space: pre-line;
}

.iframeBox {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.container-fluid img {
  width: 100%;
}
